import './Testimonials.scss';
import React from 'react';
import Carousel from '../Carousel';
import Image from '../../atoms/Image/Image';

export const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  focusOnSelect: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        className: 'slick-no-center',
        arrows: false,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        className: 'slick-no-center',
        arrows: false,
      },
    },
  ],
};

const testimonials = [
  {
    id: '1',
    name: 'Steen Møller',
    position: 'Enterprise Architect @ Stark',
    text: `As the front end took shape, there was a need for rights management in an event-based architecture. This is where Ocelot came into play and has easily secured
    context-driven rights in the microservice architecture. YanchWare has been a key player in the momentum in forming a new architecture and baseline for the new STARK ERP`,
    imageName: 'stark',
    imageType: 'svg',
  },
  {
    id: '2',
    name: 'Pernille Geneser',
    position: 'CIO @ Stark',
    text: `YanchWare has been with us all the way and in a short time has gained a connection between the old and the
    new world in cooperation with other partners.
    STARK have used that success to build a new frontend for the old ERP system based on Events provided by YanchWare’s effort`,
    imageName: 'stark',
    imageType: 'svg',
  },
  {
    id: '4',
    name: 'Want to leave yours?',
    position: 'Please use the contact form',
    text: 'Your text displayed here',
    imageName: 'press',
    imageType: 'svg',
  },
  {
    id: '3',
    name: 'Targit',
    position: '',
    text: 'Coming soon',
    imageName: 'targit',
    imageType: 'svg',
  },
];

const SupportedComponents = () => {
  return (
    <Carousel className="testimonials-carousel" settings={carouselSettings}>
      {testimonials.map((demoTestimonial, i) => {
        const { imageName, imageType, name, position, text } = demoTestimonial;
        return (
          <div className="testimonial-item" key={`testimonial-item-${i}`}>
            <Image className="testimonial-item__image" name={imageName} type={imageType} />
            <div className="testimonial-item__name">{name}</div>
            <div className="testimonial-item__position">{position}</div>
            <div className="testimonial-item__text">
              <div className="testimonial-item__inner-text">{`“${text}”`}</div>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default SupportedComponents;

import React, { FC } from 'react';

import Image from 'components/atoms/Image/Image';
import Carousel from '../Carousel/Carousel';
import { carouselSettings } from 'components/Carousel/Testimonials-old/Testimonials';

type ProductSliderImageProps = {
  images: string[];
  titles: string[];
};

const ProductSliderImage: FC<ProductSliderImageProps> = ({ images, titles }) => {

  return <Carousel className="w-[81vw] h-[220px] mb-[100px] product-slider" settings={{ ...carouselSettings, className: "w-[50px]" }} autoplaySpeed={3000}>
    {images.map((image, i) => {
      return (
        <div className="w-full" key={`testimonial-item-${i}`}>
          <Image className="w-full h-[220px] block" name={image} key={`product-slider-image-${i}`} />
          <div className="flex">
            <div className="max-w-[560px] mt-xs mb-l text-[14px] text-blue-1000 text-center">
              {titles[i]}
            </div>
          </div>
        </div>
      );
    })}
  </Carousel>;
};

export default ProductSliderImage;